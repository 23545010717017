import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { MailIcon, PhoneIcon } from "@heroicons/react/solid"

const IndexPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Ochrana osob a majetku, správa nemovitostí | Holding Malgen</title>
    </Helmet>
    <main className="pb-8">
      {/* Hero card */}
      <div className="relative md:py-5">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-200" />
        <div className="max-w-7xl mx-auto  ">
          <div className="relative shadow-xl sm:rounded-lg sm:overflow-hidden">
            <div className="absolute inset-0">
              <StaticImage
                src="../images/moderni.jpg"
                className="h-full w-full object-cover border border-gray-200 sm:rounded-lg"
                alt="Malgen"
              />
              <div className="absolute inset-0 bg-gray-300 mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-14">
              <h1 className="text-center lg:text-right text-3xl  md:text-5xl grid ">
                <span className=" text-white">
                  <span className="font-bold">Realitní</span> služby, správa
                  nemovitostí
                </span>
                <span className=" text-white">
                  <span className="font-bold">Ostraha</span> osob a majetku
                </span>
              </h1>

              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-end">
                <div className="space-y-4 sm:space-y-0  sm:inline-grid sm:grid-cols-3 sm:gap-5">
                  <Link
                    to={"/reality/"}
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                  >
                    Realitní služby
                  </Link>
                  <Link
                    to={"/sluzby-ostrahy-a-spravy-majetku/"}
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 bg-white hover:bg-blue-50 sm:px-8"
                  >
                    Ostraha nemovitostí
                  </Link>
                  <Link
                    to={"/sprava-a-udrzba-nemovitosti/"}
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                  >
                    Facility management
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-200 pt-12 sm:pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center  ">
            <h2 className="text-2xl inset-0 sm:text-4xl">
              Chcete <span className="font-extrabold">strážce</span> svého času?
            </h2>
            <p className="mt-3 text-lg opacity-80 sm:mt-4">
              Jsme dodavatelé jistoty, dozorem vašeho diáře i majetku.
            </p>
          </div>
        </div>
        <div className="mt-10 pb-12 bg-white sm:pb-16">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-200" />
            <div className="relative max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-6xl mx-auto ">
                <div className="rounded-lg  grid grid-cols-2 md:grid-cols-4  overflow-hidden">
                  <Link
                    to={"/reality"}
                    className="group flex flex-col  text-center sm:border-0  relative m-3 overflow-hidden rounded-md border "
                  >
                    <StaticImage
                      src="../images/dum.jpg"
                      className=" transform  group-hover:scale-110 ease-in-out duration-150"
                      alt="Malgen"
                    />
                    <span className="truncate px-4  py-3 font-medium  text-gray-900 border border-gray-300 grid absolute  bottom-0 left-0 right-0 rounded-b-md backdrop-filter   bg-gray-200 group-hover:bg-white ease-in-out duration-150 ">
                      Reality
                    </span>
                  </Link>
                  <Link
                    to={"/fotovoltaika"}
                    className="group flex flex-col  text-center sm:border-0  relative m-3 overflow-hidden rounded-md border "
                  >
                    <StaticImage
                      src="../images/panely-strecha.jpg"
                      className=" transform  group-hover:scale-110 ease-in-out duration-150"
                      alt="Malgen"
                    />
                    <span className="truncate px-4  py-3 font-medium  text-gray-900 border border-gray-300 grid absolute  bottom-0 left-0 right-0 rounded-b-md backdrop-filter   bg-gray-200 group-hover:bg-white ease-in-out duration-150 ">
                      Fotovoltaika
                    </span>
                  </Link>
                  <Link
                    to={"/sluzby-ostrahy-a-spravy-majetku"}
                    className="group flex flex-col  text-center sm:border-0  relative m-3 overflow-hidden rounded-md border "
                  >
                    <StaticImage
                      src="../images/shutterstock_485743234.jpg"
                      className=" transform  group-hover:scale-110 ease-in-out duration-150"
                      alt="Malgen"
                    />
                    <span className="truncate px-4  py-3 font-medium  text-gray-900 border border-gray-300 grid absolute  bottom-0 left-0 right-0 rounded-b-md backdrop-filter   bg-gray-200 group-hover:bg-white ease-in-out duration-150 ">
                      Security & Facility
                    </span>
                  </Link>
                  {/* <Link
                    to={"/sluzby-pro-zacinajici-nebo-fungujici-firmy/"}
                    className="group flex flex-col  text-center sm:border-0  relative m-3 overflow-hidden rounded-md border "
                  >
                    <StaticImage
                      src="../images/rawpixel-com-567016-unsplash.jpg"
                      className=" transform  group-hover:scale-110 ease-in-out duration-150"
                      alt="Malgen"
                    />
                    <span className="truncate px-4  py-3 font-medium  text-gray-900 border border-gray-300 grid absolute  bottom-0 left-0 right-0 rounded-b-md backdrop-filter   bg-gray-200 group-hover:bg-white ease-in-out duration-150 ">
                      Consulting
                    </span>
                  </Link> */}
                  <Link
                    to={"/concierge/"}
                    className="group flex flex-col  text-center sm:border-0  relative m-3 overflow-hidden rounded-md border "
                  >
                    <StaticImage
                      src="../images/andre-hunter-297773-unsplash.jpg"
                      className=" transform  group-hover:scale-110 ease-in-out duration-150"
                      alt="Malgen"
                    />
                    <span className="truncate px-4  py-3 font-medium  text-gray-900 border border-gray-300 grid absolute  bottom-0 left-0 right-0 rounded-b-md backdrop-filter   bg-gray-200 group-hover:bg-white ease-in-out duration-150 ">
                      Concierge
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-12 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
                Kávu platíme my
              </h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Pomáháme firmám i jednotlivcům
              </p>
              <p className="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                K vašim potřebám přistoupíme individuálně. Je to jednoduché,
                zavolejte nám nebo pošlete e-mail. Na základě Vašich požadavků
                zašleme nabídku, nebo si s vámi dáme schůzku u dobré kávy.
              </p>
            </div>

            <div className="mt-10 grid justify-start space-y-8 lg:flex lg:justify-center lg:space-x-8 lg:space-y-0">
              <div className="flex lg:justify-center items-center">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                  <PhoneIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="ml-3 text-xl  font-medium text-gray-900">
                  800 991 100
                </p>
              </div>
              <div className="flex lg:justify-center items-center">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                  <MailIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <a
                  href="mailto:office@malgen.cz"
                  className="ml-3 text-xl  font-medium text-gray-900"
                >
                  office@malgen.cz
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </>
)

export default IndexPage
